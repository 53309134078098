.arrow-up{
  height: 20px;
  width: 20px;
  border: 1px solid black;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,1);

  position: absolute;
  z-index: 5;
  text-decoration: none;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.8;
  transition: 0.2s ease-in-out;

  top: 5px;
}
.arrow-up:hover {
  font-size: 25px;
  opacity: 1;
  width: 25px;
  height: 25px;
  margin: -3px;
}

.arrow-down{
  height: 20px;
  width: 20px;
  border: 1px solid black;
  clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,1);
  transform: rotate(180deg);

  position: absolute;
  z-index: 5;
  text-decoration: none;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.8;
  transition: 0.2s ease-in-out;

  bottom: 5px;
}
.arrow-down:hover {
  font-size: 25px;
  opacity: 1;
  width: 25px;
  height: 25px;
  margin: -3px;
}

.bio-power-up {
  position: absolute;
  z-index: 5;
  text-decoration: none;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.8;
  transition: font-size 0.3s;

  top: 0px;
}
.bio-power-up:hover {
  font-size: 25px;
  opacity: 1;
}
.power-up-ss {
  color: yellow;
  background: yellow;
  right: 5px;
}
.power-up-2ss {
  color: yellow;
  background: yellow;
  right: 30px;
}
.power-up-ultimate {
  color: white;
  background: white;
  right: 30px;
}
.power-up-majin {
  color: pink;
  background: pink;
  right: 30px;
}

.bio-power-down {
  position: absolute;
  z-index: 5;
  text-decoration: none;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.8;
  transition: font-size 0.3s;

  bottom: 2px;
}
.bio-power-down:hover {
  font-size: 25px;
  opacity: 1;
}
.power-down-base {
  color: white;
  background: white;
  right: 5px;
}

.stat-circle1 {
  border: 1px solid rgba(0,0,0,0);
  /* background: linear-gradient(rgb(0, 144, 188), rgb(128, 134, 201), rgb(128, 134, 201), rgb(119, 178, 213)); */
  background: linear-gradient(rgb(220, 220, 220), rgb(120, 120, 120));
  width: 65%;
  height: 65%;
  border-radius: 100%;
  position: absolute;
  top: 20%;
  left: 14%;
  z-index: -1;
  padding: 10px;
}

.stat-circle2 {
  border: 2px solid black;
  
  /* background: radial-gradient(rgb(63, 69, 89) 15%, transparent 16%) 1px 1px,
  radial-gradient(rgb(63, 69, 89) 15%, transparent 16%) 8px 8px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
  radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 8px;
  background-color: rgb(17, 56, 72);
  background-size:16px 16px; */

  background: rgb(0,130,0);
  background-image: linear-gradient(rgba(0, 0, 0, .5) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 0, 0, .5) 1px, transparent 1px);
  background-size: 35px 35px;

  width: 90%;
  height: 90%;
  border-radius: 100%;
  position: absolute;
  margin: 10px;
  z-index: -1;
}


@media (max-width: 768px) {
  .stats-radar {
    display: none;
  }
  .stats-bars {
    display: block;
  }
}

@media (min-width: 768px) {
  .stats-radar {
    display: block;
  }
  .stats-bars {
    display: none;
  }
}
