.bm-balance-grid img{
  margin: 1px;
  width: 60px;
  cursor: pointer;
  transition: 0.3s;
  border: 2px solid transparent;
}
.bm-balance-grid img:hover{
  border: 2px solid white;
}
.bm-balance-grid{
  margin: 1.25rem -1.5rem 1.25rem -1.5rem;
}

.bm-balancer-table {
  width: 100%;
  border: 1px solid white;
}
.bm-balancer-table thead{
  border: 3px solid white;
  text-align: center;
  font-weight: bold;
}
.bm-balancer-table thead tr td{
  border: 3px solid white;
}

.bm-balancer-table tr td:nth-child(2){
  border-right: 3px solid white;
}

.bm-balancer-table td{
  border: 1px solid white;
  padding: 5px;
}

.bm-balancer-table.team1 tbody td:nth-child(even){
  text-align: center;
}
.bm-balancer-table.team1 tbody td:nth-child(odd){
  text-align: right;
}

.bm-balancer-table.team2 tbody td:nth-child(odd){
  text-align: center;
}
.bm-balancer-table.team2 tbody td:nth-child(even){
  text-align: left;
}