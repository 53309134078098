.version-label {
  bottom: 10%;
  right: 5%;
}

.fa-icon path{
  transition: 0.3s;
}
.fa-icon svg:hover path{
  fill: #5582FF;
}

.footer-links a {
  color: white;
}