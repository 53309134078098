.text-shadow-label {
  text-shadow: 0 0 2px rgba(0, 0, 0, 1);
}

/*
Saga Labels
*/
.saiyan-saga-label {
  background-color: #FFED4A;
}

.namek-saga-label {
  background-color: #45a329;
}

.android-saga-label {
  background-color: #ff0000;
}

.buu-saga-label {
  background-color: #ff99ff;
}

.super-saga-label {
  background-image: radial-gradient(white, yellow, orange, red);
  background-position: 25% 75%;
}

.bog-saga-label {
  background-image: linear-gradient(180deg , #fcb63c, #f8f0a8, #f6bd30);
}
.rf-saga-label {
  background-image: linear-gradient(90deg , #d0b449, #8a659f, #f6bd30);
}
.ft-saga-label {
  background-image: linear-gradient(180deg , #f06e88, #727086, #4b475e);
}

/*
Race Labels
*/
.android-race-label {
  background-color: #ff0000;
}
.bio-android-race-label {
  background-image: linear-gradient(90deg, #FFED4A, #45a329, #00b0f0, #cc0099 );
}

.majin-race-label {
  background-color: #ff99ff;
}

.human-race-label {
  background-color: #00b0f0;
}

.namekian-race-label {
  background-color: #45a329;
}

.saiyan-race-label {
  background-color: #FFED4A;
}

.frieza-race-label {
  background-color: #cc0099;
}

.alien-race-label {
  background-color: #b7b7b7;
}

.commeson-race-label {
  background-color: #BA009E;
}

/*
Release Labels
*/
.release-label {
  background-color: gray;
}

#ss-aura-flash {
  display: none;
}

.bio-ss-aura {
  display: block !important;
  position: absolute;
  opacity: 1;
  bottom: 15px;
  left: -15px;
  z-index: 2;
}
.bio-ss-aura-flash {
  animation: fadeOut 0.35s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}