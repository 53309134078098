.version-label {
  bottom: 10%;
  right: 5%;
}
.video-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.video-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.hero-logos .dbz-logo {
  width: 45%;
  margin-top: -100px;
  margin-bottom: -100px;
}
.hero-logos .lf2-logo {
  width: 55%;
  margin-top: -100px;
  margin-bottom: -100px;
}
.hero-logos .version {
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .hero-logos .dbz-logo {
    margin-top: -100px;
    margin-bottom: -140px;
  }
  .hero-logos .lf2-logo {
    margin-top: -100px;
    margin-bottom: -140px;
  }
  .hero-logos .version {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .hero-logos .dbz-logo {
    width: 100%;
  }
  .hero-logos .lf2-logo {
    width: 100%; 
  }
}

@media (max-width: 576px) {
  .hero-logos .dbz-logo {
    margin-top: -100px;
    margin-bottom: -125px;
  }
  .hero-logos .lf2-logo {
    margin-top: -125px;
    margin-bottom: -75px;
  }
  .hero-logos .version {
    margin-bottom: 50px;
  }
}




.home-square {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.home-box {
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  height: 100%;
}

@media (max-width: 992px) {
  .home-box {
    min-height: 350px;
  }
}

@media (max-width: 768px) {
  .home-box {
    min-height: 100%;
    margin: 0 auto;
  }
  .home-square-container {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}


.text-orange {
  color: orange;
}