@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion-faqs h3 {
  margin-left: 30px;
  margin-top: -22px;
}

.accordion-faqs a {
  color: red;
}

.accordion-faqs-content {
  margin-left: 30px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion.active {
  border-bottom: none;  
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion:focus {
  outline: none;
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transition: transform 0.7s ease-in-out;
  margin-right: 10px;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: hidden;
  transition: 0.7s ease-in-out;
}
.accordion__content.active {
  margin-bottom: 1.25rem;
}

.downloads-section ul {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}
.downloads-section li:before {
  content: "●";
  font-weight: 800;
  margin-right: 6px;
}
.list-plus:before {
  content: "\002B" !important;
  color: green;
  font-weight: 700 !important;
  margin-right: 4px !important;
}
.list-minus:before {
  content: "−" !important;
  color: red;
  font-weight: 800 !important;
  margin-right: 4px !important;
}