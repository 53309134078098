.character-card
{
  transition: 0.3s ease;
  font-weight: 100;
  max-width: 175px;
  height: 196px;
  width: 100%;
  color: white;
}

.character-card .card-text
{
  background-color: black;
  margin-top: -3px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-size: 20px;
}
.card-text p {
  margin-left: 0;
  margin-right: -46px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.character-card .name-swap
{
  background-color: black;
  margin-top: -3px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-size: 20px;
}
.name-swap p {
  margin-left: 0;
  margin-right: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.name-swap {
  opacity: 0;
  transition: 0.5s ease;
  height: 66px;
  transform: translateY(100%);
}
.character-card:hover .name-swap {
  opacity: 1;
  transform: translateY(-0%);
}


.character-card:hover
{
  font-weight: bolder;
  box-shadow: 0 0 5px 3px rgba(90, 119, 216, 0.85);
  color: #5582FF
}

.character-card img {
  transition: 0.3s ease;
}


/* .character-card .anime-face:hover  {
  transform: scale(1.1);
  opacity: 0.0;
} */
.anime-face:hover {
  transform: scale(1.1);
  opacity: 0.0;
}

.pixel-face {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 175px;
  z-index: 1;
  opacity: 0;
  image-rendering: pixelated;
  transform: translateY(-100%);
}

.character-card:hover .anime-face {
  transition: 0.5s ease;
  opacity: 0;
  transform: translateY(100%);
}
.character-card:hover .pixel-face {
  transition: 0.5s ease;
  opacity: 1;
  transform: translateY(0);
}

.card-text {
  opacity: 1;
  transition: 0.5s ease;
  height: 66px;
}
.character-card:hover .card-text {
  opacity: 0;
  transform: translateY(100%);
}

.char-profile-pixel {
  display: flex;
}
.char-profile-pixel-img img {
  height: 100px;
  image-rendering: pixelated;
}
.char-profile-pixel-header {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pixelated{
  image-rendering: pixelated;
}